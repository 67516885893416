var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_vm._t("action",function(){return [(
        ['Assessment venture', 'Assessment reviewer'].includes(_vm.outcome.stage)
      )?_c('div',[_c('v-btn',{attrs:{"ripple":false,"elevation":"0","to":_vm.outcomeLink,"nuxt":"","color":"primary"}},[(_vm.outcome.confirmedForNextStage === 'confirmed')?_c('div',[_vm._v("\n          See detailed scoring\n        ")]):[(_vm.outcome.stage === 'Assessment venture')?_c('div',[_vm._v("\n            Edit scoring\n          ")]):_c('div',[_vm._v("Review scoring")])]],2)],1):_vm._e(),_vm._v(" "),(_vm.outcome.stage === 'Outcome-selection venture')?_c('div',[(_vm.isEditable)?_c('v-btn',{attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.$emit('set-outcome-to-edit', _vm.outcome)}}},[_vm._v("\n        Edit\n      ")]):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"value":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.isEditable)?_c('v-btn',_vm._g({attrs:{"dark":"","elevation":"0","attrs":attrs}},on),[_vm._v("\n            Delete\n          ")]):_vm._e()]}},{key:"default",fn:function(dialog){return [_c('v-card',{staticClass:"subbox"},[_c('v-card-title',[_vm._v(" Deleting outcome ")]),_vm._v(" "),_c('v-card-text',[_vm._v("\n              Are you sure you want to delete the outcome \""+_vm._s(_vm.outcome.outcome)+"\"?\n            ")]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("\n                Cancel\n              ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"danger","text":""},on:{"click":function($event){_vm.$emit('delete-outcome', _vm.outcome)
                  dialog.value = false}}},[_vm._v("\n                Delete\n              ")])],1)],1)]}}],null,false,2192634464)})],1):(_vm.outcome.stage === 'Outcome-selection reviewer')?_c('div',[(_vm.isEditable)?_c('v-btn',{staticClass:"mb-1",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.$emit('set-outcome-to-edit', _vm.outcome)}}},[_vm._v("\n        Review & Edit\n      ")]):_vm._e(),_vm._v(" "),_c('v-spacer'),_vm._v(" "),(_vm.isEditable)?_c('v-btn',{staticClass:"mb-1",attrs:{"color":"success","elevation":"0","outlined":_vm.outcome.confirmedForNextStage === 'rejected'},on:{"click":function($event){return _vm.$emit('update-outcome', {
            outcome: _vm.outcome,
            fields: {
              confirmedForNextStage: 'confirmed',
            },
          })}}},[_vm._v("\n        Accept\n      ")]):_vm._e(),_vm._v(" "),(_vm.isEditable)?_c('v-btn',{staticClass:"mb-1 white--text",attrs:{"color":"danger","outlined":_vm.outcome.confirmedForNextStage === 'confirmed',"elevation":"0"},on:{"click":function($event){return _vm.$emit('update-outcome', {
            outcome: _vm.outcome,
            fields: {
              confirmedForNextStage: 'rejected',
            },
          })}}},[_vm._v("\n        Reject\n      ")]):_vm._e()],1):_vm._e()]},{"outcome":_vm.outcome})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }