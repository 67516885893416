import "core-js/modules/es6.object.to-string.js";
import "core-js/modules/es6.date.to-string.js";
import "core-js/modules/es6.regexp.to-string.js";
export default defineComponent({
  props: {
    assessment: {
      type: Object,
      required: true
    },
    outcome: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },
  setup: function setup() {},
  computed: {
    outcomeLink: function outcomeLink() {
      return "/".concat(this.assessment.id.toString(), "/impact/assessment/").concat(this.outcome.id);
    }
  }
});